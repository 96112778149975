import React from "react";
import { Router } from "@reach/router";

import LoginContainer from "Features/Login/LoginContainer";
import LoginRedirectContainer from "Features/LoginRedirect/LoginRedirectContainer";
import RequestExpiredContainer from "Features/RequestExpired/RequestExpiredContainer";
import ThankYouContainer from "Features/ThankYou/ThankYouContainer";
import VerificationContainer from "Features/Verification/VerificationContainer";

const Routes = () => {
  return (
    <Router>
      <LoginContainer path="/" />
      <LoginRedirectContainer path="/*" />
      <RequestExpiredContainer path="/request-expired" />
      <ThankYouContainer path="/thanks" />
      <VerificationContainer path="/verification" />
    </Router>
  );
};

export default Routes;

import React, { useEffect } from "react";
import get from "lodash.get";
import { useStoreState } from "hooks";
import { RouteComponentProps, navigate } from "@reach/router";

import RequestExpiredComponent from "./RequestExpiredComponent";

function RequestExpiredContainer(_: RouteComponentProps) {
  const dateOfRequest = useStoreState(state =>
    get(state, "status.entries.auth.login.result.dateOfRequest"),
  );

  const hasLoginSucceeded = useStoreState(
    state => state.status.getLastActionType("auth", "login") === "success",
  );
  useEffect(() => {
    if (!hasLoginSucceeded) navigate("/");
  }, [hasLoginSucceeded]);

  return <RequestExpiredComponent dateOfRequest={dateOfRequest} />;
}

export default RequestExpiredContainer;

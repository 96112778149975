import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
  Container,
} from "@material-ui/core";

import ExpirationDateContainer from "./Components/ExpirationDateContainer";
import InstructionsComponent from "./Components/InstructionsComponent";
import PatientInformationComponent from "./Components/PatientInformationComponent";
import ProductInformationContainer from "./Components/ProductInformationContainer";
import QuestionsComponent from "Components/QuestionsComponent";
import { OrderInfo, PatientInfo } from "store/prescription";

const useStyles = makeStyles(() => ({
  buttonVerify: {
    borderRadius: 1,
    boxShadow: "none",
    height: 48,
    lineHeight: "normal",
    padding: "12px 0",
    margin: "20px 0 40px 0",
    maxWidth: "280px",
    textTransform: "capitalize",
    width: "30%",
  },
  buttonNoPrescription: {
    margin: "30px 0 50px 0",
    display: "inline-block",
    textAlign: "center",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    height: 48,
    fontFamily: "proxima-nova",
    fontWeight: 600,
    minWidth: "280px",
    cursor: "pointer",
    color: "rgb(65, 75, 86)",
    backgroundColor: "transparent",
    textTransform: "lowercase",
    textShadow: "none",
    borderRadius: 1,
    transition: "background-color 0.4s ease 0s",
    padding: "6px 16px",
    outline: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgb(161, 164, 166)",
    borderImage: "initial",
    boxShadow: "none",
    "&:hover": {
      background: "transparent",
      boxShadow: "none",
      color: "rgb(65, 75, 86)",
      borderColor: "rgb(65, 75, 86)",
    },
  },
  container: {
    paddingTop: "90px",
    maxWidth: 1200,
  },
  dateTime: {
    textAlign: "left",
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
  dateTimeLabel: {
    fontWeight: 600,
  },
  dateTimeWrapper: {
    marginTop: "20px",
  },
  divider: {
    border: "1px solid #E4E6E8",
  },
  errorText: {
    color: "red",
  },
  patientFileTitle: {
    color: "#414B56",
    fontFamily: "proxima-nova",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "28px",
    marginTop: 30,
    textAlign: "left",
  },
  patientFileInstructions: {
    marginTop: 10,
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
    width: 650,
  },
  productInfoContainer: {
    padding: 0,
  },
  productInfoTitle: {
    color: "#414B56",
    fontFamily: "proxima-nova",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "28px",
    textAlign: "left",
  },
  scoutTrialCheckbox: {
    fontFamily: "proxima-nova",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "28px",
    marginTop: 60,
    width: 681,
  },
  scoutTrialCheckboxLabel: {
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
  section: {
    marginTop: "60px",
  },
  spacer: {
    paddingBottom: 96,
  },
  submitSection: {
    textAlign: "left",
  },
  title: {
    textAlign: "left",
    fontFamily: "utopia-std-display, serif",
    fontSize: 40,
    lineHeight: "44px",
  },
  textLink: {
    color: "#00A2E1",
    cursor: "pointer",
    textDecoration: "none",
    fontFamily: "proxima-nova",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "28px",
  },
  warning: {
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
}));

interface LabeledCheckboxProps {
  onChange: (bool: boolean) => void;
}

// Broke out from VerificationComponent to avoid mount-based testing errors.
export const LabeledCheckbox = ({ onChange }: LabeledCheckboxProps) => {
  const { scoutTrialCheckbox, scoutTrialCheckboxLabel } = useStyles();

  const handleOnChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    bool: boolean,
  ) => {
    onChange(bool);
  };

  return (
    <FormControlLabel
      classes={{
        label: scoutTrialCheckboxLabel,
      }}
      className={scoutTrialCheckbox}
      control={
        <Checkbox
          id="scoutTrial"
          color="primary"
          disableRipple
          onChange={handleOnChange}
          value="oneTimeUse"
        />
      }
      label="I would be interested in receiving Scout trial lenses in my patient's prescription when available so I can assess fit with my patient directly"
    />
  );
};

interface Props {
  hasScoutLensInForm: boolean;
  hasScoutLensInOriginalRx: boolean;
  hasSubmitFailed: boolean;
  prescriptionInfo: {
    orderInfo: OrderInfo;
    patientInfo: PatientInfo;
  };
  rejectPrescription: () => void;
  submitPrescription: () => void;
  toggleHasRequestedScoutTrial: (bool: boolean) => void;
}

function VerificationComponent({
  hasScoutLensInForm,
  hasScoutLensInOriginalRx,
  hasSubmitFailed,
  prescriptionInfo: { orderInfo, patientInfo },
  rejectPrescription,
  submitPrescription,
  toggleHasRequestedScoutTrial,
}: Props) {
  const {
    container,
    dateTime,
    dateTimeLabel,
    dateTimeWrapper,
    errorText,
    divider,
    patientFileInstructions,
    patientFileTitle,
    productInfoContainer,
    productInfoTitle,
    section,
    submitSection,
    buttonVerify,
    buttonNoPrescription,
    spacer,
    title,
  } = useStyles();

  return (
    <Container className={container}>
      <Typography id="title" variant="h4" className={title}>
        Contacts Prescription Verification
      </Typography>
      <Grid className={dateTimeWrapper}>
        <Typography className={dateTime} id="dateOfRequest" variant="body1">
          <span className={dateTimeLabel}>Date of Request:</span>
          {` ${orderInfo.dateOfRequest}`}
        </Typography>
        <Typography className={dateTime} id="requestExpiration" variant="body1">
          <span className={dateTimeLabel}>Request Expiration:</span>
          {` ${orderInfo.expires}`}
        </Typography>
      </Grid>
      <InstructionsComponent
        hasScoutLensInOriginalRx={hasScoutLensInOriginalRx}
      />
      <Grid container direction="row">
        <Grid item xs={12} sm={12}>
          <PatientInformationComponent
            orderInfo={orderInfo}
            patientInfo={patientInfo}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ExpirationDateContainer />
        </Grid>
      </Grid>
      <Grid className={section}>
        <Typography
          id="productInformation"
          variant="body1"
          className={productInfoTitle}
        >
          Product Information
        </Typography>
        <Container className={productInfoContainer}>
          <Grid container direction="row">
            <Grid item xs={12} sm={5}>
              <ProductInformationContainer
                eye={"od"}
                eyeName={"OD (Right eye)"}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <ProductInformationContainer
                eye={"os"}
                eyeName={"OS (Left eye)"}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid className={submitSection} container direction="column">
        {hasScoutLensInOriginalRx && !hasScoutLensInForm && (
          <LabeledCheckbox onChange={toggleHasRequestedScoutTrial} />
        )}
        <Button
          className={buttonVerify}
          color="primary"
          id="submitButton"
          onClick={submitPrescription}
          variant="contained"
        >
          Verify prescription
        </Button>
        {hasSubmitFailed && (
          <Typography className={errorText} id="submitError" variant="body1">
            Oops! There was an error submitting your request. Make sure all
            required fields have been entered.
          </Typography>
        )}
      </Grid>
      <div className={divider} />
      <Grid container direction="row" className={section}>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1" className={patientFileTitle}>
            Not your patient?
          </Typography>
          <Typography className={patientFileInstructions} variant="body1">
            No worries. If you don’t have a contact lens prescription on file
            for this patient, we’ll let them know they submitted the incorrect
            eye doctor’s information. Choosing this will void the prescription
            request.
          </Typography>
          <Button
            className={buttonNoPrescription}
            color="primary"
            variant="contained"
            id="noPrescription"
            onClick={rejectPrescription}
          >
            Not my contact lens patient
          </Button>
        </Grid>
      </Grid>
      <QuestionsComponent />
      <div className={spacer}></div>
    </Container>
  );
}

export default VerificationComponent;

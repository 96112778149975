import Logger from "@warbyparker/logger";

interface Logger {
  fatal: (data: any, msg?: string) => void;
  error: (data: any, msg?: string) => void;
  info: (data: any, msg?: string) => void;
  debug: (data: any, msg?: string) => void;
}

const logger: Logger = new Logger({
  name: "rx_verification_portal",
  environment: Object.assign({}, process.env, {
    hostname: window.location.hostname,
  }),
  stream: {
    enabled: process.env.NODE_ENV === "production",
    level: "info",
  },
});

function conditionalWrapper(
  func: typeof Logger.fatal,
  data: any,
  msg?: string,
) {
  if (process.env.NODE_ENV === "test") return;

  func(data, msg);
}

const testEnvLogger: Logger = {
  fatal: (data, msg) => conditionalWrapper(logger.fatal, data, msg),
  error: (data, msg) => conditionalWrapper(logger.error, data, msg),
  info: (data, msg) => conditionalWrapper(logger.info, data, msg),
  debug: (data, msg) => conditionalWrapper(logger.debug, data, msg),
};

export default process.env.NODE_ENV === "test" ? testEnvLogger : logger;

import { PatientInfo } from "./prescription";

export const formatFullAddress = (patientInfo: PatientInfo) => {
  const optionalExtension = patientInfo.extendedAddress
    ? ` ${patientInfo.extendedAddress}`
    : "";

  return `${patientInfo.streetAddress}${optionalExtension} \n${patientInfo.locality}, \
${patientInfo.region} ${patientInfo.postalCode}`;
};

import React, { useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";

import VerificationComponent from "./VerificationComponent";
import { useStoreActions, useStoreState } from "hooks";

function VerificationContainer(_: RouteComponentProps) {
  const getProductLines = useStoreActions(
    actions => actions.products.getProductLines,
  );
  useEffect(() => {
    getProductLines();
  }, [getProductLines]);

  const initializeFormData = useStoreActions(actions => {
    return actions.prescription.initializeFormData;
  });
  useEffect(() => {
    initializeFormData();
  }, [initializeFormData]);

  const prescriptionInfo = useStoreState(state => {
    return {
      orderInfo: state.prescription.orderInfo,
      patientInfo: state.prescription.patientInfo,
      valuesByEye: state.prescription.valuesByEye,
    };
  });
  const hasScoutLensInForm = useStoreState(state => {
    const odProductIdentifier =
      state.prescription.formData.od.productIdentifier;
    const osProductIdentifier =
      state.prescription.formData.os.productIdentifier;

    return (
      state.products.isScout(odProductIdentifier) ||
      state.products.isScout(osProductIdentifier)
    );
  });
  const hasScoutLensInOriginalRx = useStoreState(state => {
    const odValuesByEyes = state.prescription.valuesByEye.od;
    const osValuesByEyes = state.prescription.valuesByEye.os;
    const odProductIdentifier = !!odValuesByEyes
      ? odValuesByEyes.productIdentifier
      : "";
    const osProductIdentifier = !!osValuesByEyes
      ? osValuesByEyes.productIdentifier
      : "";

    return (
      state.products.isScout(odProductIdentifier) ||
      state.products.isScout(osProductIdentifier)
    );
  });
  const hasSubmitFailed = useStoreState(state => {
    return state.status.getLastActionType("prescription", "submit") === "fail";
  });

  const rejectPrescription = useStoreActions(
    actions => actions.prescription.reject,
  );
  const submitPrescription = useStoreActions(
    actions => actions.prescription.submit,
  );

  const toggleHasRequestedScoutTrial = useStoreActions(actions => {
    return (bool: boolean) =>
      actions.prescription.updateFormData({
        hasRequestedScoutTrial: bool,
      });
  });

  const hasData = useStoreState(state => !!state.prescription.data);
  if (!hasData) {
    navigate("/");
    return null;
  }

  return (
    <VerificationComponent
      hasScoutLensInForm={hasScoutLensInForm}
      hasScoutLensInOriginalRx={hasScoutLensInOriginalRx}
      hasSubmitFailed={hasSubmitFailed}
      prescriptionInfo={prescriptionInfo}
      rejectPrescription={() => rejectPrescription("rx_not_on_file")}
      submitPrescription={() => submitPrescription()}
      toggleHasRequestedScoutTrial={toggleHasRequestedScoutTrial}
    />
  );
}

export default VerificationContainer;

import React from "react";
import { Moment } from "moment";

import ExpirationDateComponent from "./ExpirationDateComponent";
import { useStoreActions, useStoreState } from "../../../hooks";

function ExpirationDateContainer() {
  const expirationDate = useStoreState(state => {
    return state.prescription.formData.expirationDate;
  });

  const oneTimeUse = useStoreState(state => {
    return state.prescription.formData.oneTimeUse;
  });

  const setExpirationDate = useStoreActions(actions => {
    return (value: Moment) => {
      actions.prescription.updateFormData({ expirationDate: value });
    };
  });

  const setOneTimeUse = useStoreActions(actions => {
    return (value: boolean) => {
      actions.prescription.updateFormData({ oneTimeUse: value });
    };
  });

  return (
    <ExpirationDateComponent
      expirationDate={expirationDate}
      oneTimeUse={oneTimeUse}
      setExpirationDate={setExpirationDate}
      setOneTimeUse={setOneTimeUse}
    />
  );
}

export default ExpirationDateContainer;

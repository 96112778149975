import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "hooks";
import { RouteComponentProps, navigate } from "@reach/router";

import ThankYouComponent from "./ThankYouComponent";

function ThankYouContainer(_: RouteComponentProps) {
  const hasSubmitSucceeded = useStoreState(
    state =>
      state.status.getLastActionType("prescription", "submit") === "success",
  );
  const hasRejectSucceeded = useStoreState(
    state =>
      state.status.getLastActionType("prescription", "reject") === "success",
  );

  const resetPrescriptionData = useStoreActions(
    actions => actions.prescription.resetData,
  );
  useEffect(() => {
    resetPrescriptionData();
  }, [resetPrescriptionData]);

  useEffect(() => {
    if (!hasSubmitSucceeded && !hasRejectSucceeded) navigate("/");
  }, [hasSubmitSucceeded, hasRejectSucceeded]);

  return <ThankYouComponent />;
}

export default ThankYouContainer;

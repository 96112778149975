import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  download: {
    textDecoration: "none",
    color: "#00A2E1",
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
    fontWeight: 600,
  },
  instructions: {
    marginTop: "20px",
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
    width: 650,
  },
  instructionItemNumber: {
    color: "#676F78",
    display: "inline-block",
    fontFamily: "proxima-nova",
    minWidth: "25px",
  },
  instructionItemsWrapper: {
    marginTop: "1em",
  },
  instructionItemText: {
    fontFamily: "proxima-nova",
    fontSize: "18px",
    marginTop: "6px",
  },
}));

interface Props {
  hasScoutLensInOriginalRx: boolean;
}

function InstructionsComponent({ hasScoutLensInOriginalRx }: Props) {
  const {
    download,
    instructions,
    instructionItemNumber,
    instructionItemsWrapper,
    instructionItemText,
  } = useStyles();

  const downloadLink = (
    <a
      className={download}
      href="/About Scout by Warby Parker.pdf"
      target="_blank"
    >
      here
    </a>
  );

  return (
    <>
      <Typography className={instructions} id="instructions" variant="body1">
        Thanks for accessing the Warby Parker prescription verification portal.
        Below is the prescription and order information as reported by the
        patient. Please follow the steps below to accept or correct this
        prescription:
      </Typography>
      <Grid className={instructionItemsWrapper}>
        <Typography className={instructionItemText} id="instructionsReview">
          <span className={instructionItemNumber}>1.{"  "}</span>
          Review the values below as reported by the patient.
        </Typography>
        <Typography className={instructionItemText} id="instructionsVerify">
          <span className={instructionItemNumber}>2. </span>
          If you approve this prescription, click “verify” to submit your
          approval.
        </Typography>
        <Typography className={instructionItemText} id="instructionsChanges">
          <span className={instructionItemNumber}>3. </span>
          If you recommend any changes or wish to reject this prescription,
          please select the recommended brand and values using the drop-down
          menus on each field. After updating the prescription, click “verify”
          to submit any changes. We’ll email the patient directly to inform them
          of any edits and update or cancel their order accordingly.
        </Typography>
        <Typography className={instructionItemText} id="instructionsNotPatient">
          <span className={instructionItemNumber}>4. </span>
          If this is not your patient, or they do not have a contact lens
          prescription on file, you can select “Not my contact lens patient” at
          the bottom of this request.
        </Typography>
      </Grid>

      {hasScoutLensInOriginalRx && (
        <Typography
          className={instructions}
          id="scoutInstructions"
          variant="body1"
        >
          <span className="scoutParker">About Scout by Warby Parker:</span> This
          customer has requested to try our private label daily contact lenses,
          which are made from premium hydrogel—a material that promotes maximum
          wettability, moisture retention, and breathability for all-day
          comfort. More information can be found in the product information
          section beneath the order request details.
          <br />
          <br />
          If you have any additional questions about our lenses, learn more{" "}
          {downloadLink}.
        </Typography>
      )}
    </>
  );
}

export default InstructionsComponent;

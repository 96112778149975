import camelCase from "lodash.camelcase";
import findIndex from "lodash.findindex";
import mapKeys from "lodash.mapkeys";

import { RejectionKey, SubmissionData } from "store/prescription";
import { ProductRxValue } from "store/products";
import logger from "logger";

function resolveSelfReport(path = "") {
  return `https://${(window as any).env.REACT_APP_HELIOS_DOMAIN}${path}`;
}

function resolvePim(path = "") {
  return `https://${(window as any).env.REACT_APP_PIM_DOMAIN}${path}`;
}

export function dataToCamelCase(input: any) {
  if (!Array.isArray(input)) return input;
  return input.map(item => {
    if (typeof item !== "object") return item;
    const camelCaseItem = mapKeys(item, function(value: any, key: string) {
      return camelCase(key);
    });
    return camelCaseItem;
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const recursiveDataToCamelCase = (input: any): any => {
  if (Array.isArray(input)) {
    return input.map(v => recursiveDataToCamelCase(v));
  } else if (input !== null && input.constructor === Object) {
    return Object.keys(input).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: recursiveDataToCamelCase(input[key]),
      }),
      {},
    );
  }
  return input;
};

export interface RawProductRxValuesData {
  head: [string, string][];
  body: (string | number)[][];
}

export const convertRawToProductRxValues = (
  rawData: RawProductRxValuesData,
): ProductRxValue[] => {
  const headerIndexToKeyName: { [key: number]: string } = {};
  const headersOfInterest = [
    "add",
    "axis",
    "base_curve",
    "color",
    "cyl",
    "diameter",
    "power",
  ];

  headersOfInterest.reduce((obj, headerKey) => {
    const index = findIndex(rawData.head, arr => arr[0] === headerKey);
    if (index === -1) return obj;

    obj[index] = camelCase(headerKey);
    return obj;
  }, headerIndexToKeyName);

  const productRxValues: ProductRxValue[] = [];
  rawData.body.reduce((rxValues, row) => {
    const rxValue: { [key: string]: string | number } = {};
    Object.keys(headerIndexToKeyName).forEach(key => {
      const value = row[Number(key)];
      const rxValueKey = headerIndexToKeyName[Number(key)];
      rxValue[rxValueKey] = value;
    });
    const productRxValue: ProductRxValue = {
      add: String(rxValue.add),
      axis: Number(rxValue.axis),
      baseCurve: String(rxValue.baseCurve),
      color: String(rxValue.color),
      cyl: String(rxValue.cyl),
      diameter: String(rxValue.diameter),
      power: String(rxValue.power),
    };

    productRxValues.push(productRxValue);
    return productRxValues;
  }, productRxValues);

  return productRxValues;
};

export default class Api {
  public constructor({
    jwt = "",
    xToken = "",
  }: {
    jwt?: string;
    xToken?: string;
  }) {
    this.jwt = jwt;
    this.xToken = xToken;
  }

  private jwt: string = "";
  private xToken: string = "";

  public encodeForm = (data: { [key: string]: any }) => {
    return Object.keys(data)
      .reduce((acc: string[], key: string) => {
        const datum = data[key];
        const encodedKey: string = encodeURIComponent(key);
        const encodedValue: string = !datum
          ? "NULL"
          : encodeURIComponent(String(datum));
        return acc.concat(`${encodedKey}=${encodedValue}`);
      }, [])
      .join("&");
  };

  public setXToken = (xToken: string) => {
    this.xToken = xToken;
  };

  public getXToken = (): string => this.xToken;

  // fetches with jwt for ohm endpoints
  public async fetchWithJWT(path: string) {
    const jwt = await this.getOhmJWT();
    if (!jwt) throw new Error("failed to get jwt");
    const resp = await fetch(path, {
      headers: {
        Authorization: `bearer ${jwt.replace(/_/g, "underscore")}`,
      },
    });
    return resp;
  }

  // self report requests use X-XSRFToken for auth
  public getSelfReportOptions(options = {}): RequestInit {
    return {
      headers: {
        "X-XSRFToken": this.getXToken(),
      },
      ...options,
    };
  }

  public async parse(resp: Response) {
    const contentType = resp.headers.get("content-type");

    if (contentType === "text/plain") {
      const text = await resp.text();
      try {
        const errorParts = text.split("\n").slice(1);
        const { error_msg: error } = JSON.parse(errorParts.join(""));
        throw new Error(error);
      } catch (error) {
        throw new Error(text);
      }
    }

    const data = await resp.json();

    if (!resp.ok) {
      const msg = data.message || "We’ve done something wrong";
      return Promise.reject(msg);
    }

    return dataToCamelCase(data);
  }

  // API endpoints below this point
  public async getProductLines() {
    const resp = await this.fetchWithJWT(
      resolvePim(`/v1/contact-lens-product-line`),
    );
    return this.parse(resp);
  }

  public async getProductRxValues(pcProductIds: number[]) {
    const resp = await this.fetchWithJWT(
      resolvePim(
        `/v1/product/${pcProductIds.join(",")}/contact-rxs?format=array`,
      ),
    );
    const parsedData = await this.parse(resp);
    return convertRawToProductRxValues(parsedData);
  }

  public async getOhmJWT(): Promise<string> {
    const resp = await fetch(
      resolveSelfReport("/self-report/v1/ohm-jwt-refresh"),
      this.getSelfReportOptions({
        credentials: "include",
      }),
    );

    const data = await this.parse(resp);
    return data.ohm_jwt;
  }

  public async login({
    token,
    prescriptionRequestId,
  }: {
    token: string;
    prescriptionRequestId: number;
  }) {
    const resp = await fetch(resolveSelfReport("/self-report/v1/request"), {
      method: "POST",
      body: JSON.stringify({
        prescription_request_id: prescriptionRequestId,
        token: token,
      }),
      credentials: "include",
    });

    try {
      let data = await this.parse(resp);
      const camelCasedData = recursiveDataToCamelCase(data);
      let xToken = resp.headers.get("X-Token");
      if (xToken) this.setXToken(xToken);
      return camelCasedData;
    } catch (error) {
      logger.error({ error, app: "rx-verification-portal" }, "Login failure");
      return Promise.reject(error.message);
    }
  }

  public async rejectRxRequest(
    rejectionType: RejectionKey,
    hasReqestedScoutTrials: boolean,
  ) {
    const resp = await fetch(
      resolveSelfReport("/self-report/v1/reject"),
      this.getSelfReportOptions({
        method: "POST",
        body: JSON.stringify({
          rejection_type_key: rejectionType,
          interested_in_scout_trials: hasReqestedScoutTrials,
        }),
        credentials: "include",
      }),
    );

    return this.parse(resp);
  }

  public async submitForm(submissionData: SubmissionData) {
    const resp = await fetch(
      resolveSelfReport("/self-report/v1/approve"),
      this.getSelfReportOptions({
        method: "POST",
        body: JSON.stringify(submissionData),
        credentials: "include",
      }),
    );

    return this.parse(resp);
  }
}

import { createStore } from "easy-peasy";

import Api from "../services/api";
import auth, { Auth } from "./auth";
import prescription, { Prescription } from "./prescription";
import products, { Products } from "./products";
import status, { Status } from "./status";

export interface StoreModel {
  auth: Auth;
  prescription: Prescription;
  products: Products;
  status: Status;
}

export interface Injections {
  api: Api;
}

export const storeModel: StoreModel = {
  auth,
  prescription,
  products,
  status,
};

const api = new Api({});

const store = createStore(storeModel, {
  injections: {
    api,
  },
});

export default store;

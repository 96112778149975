import { useEffect } from "react";
import { navigate, RouteComponentProps } from "@reach/router";

function LoginRedirectContainer(_: RouteComponentProps) {
  useEffect(() => {
    navigate("/");
  }, []);

  return null;
}

export default LoginRedirectContainer;

import React, { useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";

import LoginComponent from "./LoginComponent";
import { useStoreActions, useStoreState } from "hooks";

function LoginContainer(_: RouteComponentProps) {
  const login = useStoreActions(actions => actions.auth.login);
  const hasPrescriptionData = useStoreState(state => !!state.prescription.data);
  const hasLoginSucceeded = useStoreState(state => {
    return state.status.getLastActionType("auth", "login") === "success";
  });
  const hasLoginFailed = useStoreState(state => {
    return state.status.getLastActionType("auth", "login") === "fail";
  });

  useEffect(() => {
    if (hasLoginSucceeded) {
      if (hasPrescriptionData) {
        navigate("/verification");
      }
      // case when dr has an expired login
      // we receive a success with status="expired" and an expiration message
      else {
        navigate("/request-expired");
      }
    }
  }, [hasPrescriptionData, hasLoginSucceeded]);

  return <LoginComponent hasLoginFailed={hasLoginFailed} login={login} />;
}

export default LoginContainer;

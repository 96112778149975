import React from "react";
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment, { Moment } from "moment";
import momentUtils from "@date-io/moment";

const useStyles = makeStyles(theme => ({
  checkBox: {
    marginRight: 0,
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
  datePicker: {
    marginRight: 10,
    backgroundColor: "#ffff",
    fontWeight: 600,
  },
  dateInput: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.contrastText,
  },
  expirationContainer: {
    width: "fit-content",
    margin: "0 0",
    padding: "0 0",
  },
  expirationSubTitle: {
    paddingBottom: 10,
    textAlign: "left",
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
  expirationTitle: {
    color: "#414B56",
    fontFamily: "proxima-nova",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "28px",
    marginBottom: 10,
    marginTop: 20,
    textAlign: "left",
  },
}));

export interface ExpirationDateComponentProps {
  expirationDate: Moment;
  oneTimeUse: boolean;
  setExpirationDate: (value: Moment) => void;
  setOneTimeUse: (value: boolean) => void;
}

function ExpirationDateComponent({
  expirationDate,
  oneTimeUse,
  setExpirationDate,
  setOneTimeUse,
}: ExpirationDateComponentProps) {
  const {
    checkBox,
    dateInput,
    datePicker,
    expirationContainer,
    expirationTitle,
    expirationSubTitle,
  } = useStyles();

  const expirationDateBeforeToday = (date: Moment) => {
    return date.isBefore(moment().startOf("day"));
  };

  const handleExpirationDateChange = (
    expirationDate: MaterialUiPickersDate,
  ) => {
    if (expirationDate) {
      setExpirationDate(expirationDate);
    }
  };

  const handleOneTimeUseChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    oneTimeUse: boolean,
  ) => {
    setOneTimeUse(oneTimeUse);
  };

  return (
    <div className={expirationContainer}>
      <Typography id="title" variant="body1" className={expirationTitle}>
        Expiration Date
      </Typography>
      <Typography
        id="expirationDateInstructions"
        variant="body1"
        className={expirationSubTitle}
      >
        Please enter an expiration date for this prescription (required)
      </Typography>
      <MuiPickersUtilsProvider utils={momentUtils}>
        <DatePicker
          InputProps={{ className: dateInput }}
          className={datePicker}
          format="MMMM DD, YYYY"
          onChange={handleExpirationDateChange}
          value={expirationDate}
        />
      </MuiPickersUtilsProvider>
      {expirationDateBeforeToday(expirationDate) && (
        <FormControlLabel
          control={
            <Checkbox
              checked={oneTimeUse}
              color="primary"
              disableRipple
              onChange={handleOneTimeUseChange}
            />
          }
          label="Authorize for this order only (one-time use)"
          className={checkBox}
        />
      )}
    </div>
  );
}

export default ExpirationDateComponent;

import React, { useEffect } from "react";

import { useStoreState, useStoreActions } from "hooks";
import ProductInformationComponent, {
  ProductInformationBaseProps,
} from "./ProductInformationComponent";
import { Category } from "./SelectorComponent";

const categoryToFormEyeValuesKey = {
  ADD: "addition",
  Axis: "axis",
  "Base Curve (BC)": "baseCurve",
  "Product line specific": "productIdentifier",
  Color: "color",
  "Cylinder (CYL)": "cylinder",
  "Diameter (DIA)": "diameter",
  "Sphere (SPH)": "sphere",
};

interface ProductInformationContainerProps extends ProductInformationBaseProps {
  eye: "os" | "od";
}

function ProductInformationContainer({
  eye,
  eyeName,
}: ProductInformationContainerProps) {
  const eyeValues = useStoreState(state => state.prescription.formData[eye]);
  const productIdentifier = useStoreState(
    state => state.prescription.formData[eye].productIdentifier,
  );
  const getProductRxValues = useStoreActions(
    actions => actions.products.getProductRxValues,
  );
  const hasProductData = useStoreState(
    state => !!state.products.flattenedProductLines.length,
  );
  // We are intentionally using hasProductData instead of productLineOptions.length.
  // With the latter we receive a computed array. Since the array is recomputed
  // each time and is NOT a primitive we get a new array each time. Due to this
  // when use effect does its dependency comparison it thinks it has a new array
  // and retriggeres the useEffect callback even though the contents of the array
  // are the same. By using the hasProductData primitive we get around this
  // reference issue.
  // We tried to memoize this value but ran into trouble. Also normally easy-peasy
  // computed values are memoized but for some reason that does not seem to be the
  // case.
  useEffect(() => {
    const hasProductIdentifier = !!productIdentifier;
    if (hasProductData && hasProductIdentifier) {
      getProductRxValues(productIdentifier);
    }
  }, [productIdentifier, getProductRxValues, hasProductData]);
  const productLineOptions = useStoreState(
    state => state.products.flattenedProductLines,
  );
  const originalEyeValues = useStoreState(
    state => state.prescription.valuesByEye[eye],
  );
  const uniqueRxValues = useStoreState(
    state => state.products.rxValuesByProductIdentifier[productIdentifier],
  );

  const setHasNoRx = useStoreActions(actions => {
    return (hasNoRx: boolean) => {
      actions.prescription.updateFormData({
        [eye]: {
          hasNoRx: hasNoRx,
        },
      });
    };
  });

  const setValue = useStoreActions(actions => {
    return (category: Category, value: string | number) => {
      const formEyeValuesKey: string = categoryToFormEyeValuesKey[category];
      actions.prescription.updateFormData({
        [eye]: {
          [formEyeValuesKey]: value,
        },
      });
    };
  });

  return (
    <ProductInformationComponent
      eyeName={eyeName}
      eyeValues={eyeValues}
      originalEyeValues={originalEyeValues}
      productLineOptions={productLineOptions}
      setHasNoRx={setHasNoRx}
      setValue={setValue}
      uniqueRxValues={uniqueRxValues}
    />
  );
}

export default ProductInformationContainer;

import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiTextField: {
      root: {
        backgroundColor: "#FFFF",
      },
    },
  },
  palette: {
    primary: {
      main: "#00A2E1",
      dark: "#0089BF",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#414B56",
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: "Helvetica, Roboto, Arial, sans-serif",
    body1: {
      color: "#676F78",
    },
    body2: {
      color: "#676F78",
    },
    h4: {
      color: "#414B56",
    },
  },
});

export default theme;

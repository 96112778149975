import React from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import SelectorComponent, { Category } from "./SelectorComponent";
import { EyeValues, FormEyeValues } from "store/prescription";
import { FlattenedProductLineData, UniqueRxValues } from "store/products";

const useStyles = makeStyles(() => ({
  checkBoxLabel: {
    fontFamily: "proxima-nova",
    fontSize: 15,
    marginRight: 0,
    marginBottom: 20,
  },
  checkBox: {
    padding: "0 9px",
    marginTop: -20,
  },
  container: {
    marginTop: "20px",
    maxWidth: "400px",
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
  disabled: {
    opacity: 0.5,
  },
  enabled: {},
  infoItem: {
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
    color: "#676F78",
  },
  lowerComponent: {
    width: "fit-content",
  },
  list: {
    paddingLeft: 19,
    marginTop: 0,
  },
  numberOfLenses: {
    textAlign: "left",
    marginTop: "20px",
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
  productInformation: {
    margin: "30px 0 0 0",
    textAlign: "left",
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
    fontWeight: 600,
  },
  productInfoComponent: {
    marginBottom: 10,
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
    fontWeight: 600,
  },
}));

export interface ProductInformationBaseProps {
  eyeName: string;
}

interface ProductInformationProps extends ProductInformationBaseProps {
  eyeValues: FormEyeValues;
  originalEyeValues: EyeValues;
  productLineOptions: FlattenedProductLineData[];
  setHasNoRx: (hasNoRx: boolean) => void;
  setValue: (category: Category, value: string | number) => void;
  uniqueRxValues: UniqueRxValues;
}

const defaultUniqueRxValues = {
  add: [],
  axis: [],
  baseCurve: [],
  color: [],
  cyl: [],
  diameter: [],
  power: [],
};

function ProductInformationComponent({
  eyeName,
  eyeValues: {
    addition,
    axis,
    baseCurve,
    color,
    cylinder,
    diameter,
    hasNoRx,
    productIdentifier,
    sphere,
  },
  originalEyeValues: {
    material,
    oxygenPermeability,
    productIdentifier: originalProductIdentifier,
    quantityDescription,
    waterContent,
  },
  productLineOptions,
  setHasNoRx,
  setValue,
  uniqueRxValues: {
    add: additionValues,
    axis: axisValues,
    baseCurve: baseCurveValues,
    color: colorValues,
    cyl: cylinderValues,
    diameter: diameterValues,
    power: sphereValues,
  } = defaultUniqueRxValues,
}: ProductInformationProps) {
  const {
    checkBoxLabel,
    container,
    disabled,
    enabled,
    numberOfLenses,
    productInformation,
    productInfoComponent,
    lowerComponent,
    infoItem,
    list,
    checkBox,
  } = useStyles();

  const isOriginalRxProduct =
    String(productIdentifier) === String(originalProductIdentifier);

  const handleSetHasNoRx = (
    _event: React.ChangeEvent<HTMLInputElement>,
    hasNoRx: boolean,
  ) => {
    setHasNoRx(hasNoRx);
  };

  return (
    <Grid className={container}>
      <Typography id="header" variant="body1" className={productInfoComponent}>
        {eyeName}
      </Typography>
      <FormControlLabel
        classes={{
          label: checkBoxLabel,
        }}
        control={
          <Checkbox
            checked={hasNoRx}
            className={checkBox}
            color="primary"
            disableRipple
            onChange={handleSetHasNoRx}
          />
        }
        label="This patient doesn’t require vision correction for this eye"
      />
      <Grid
        className={hasNoRx ? disabled : enabled}
        container
        direction={"column"}
      >
        <SelectorComponent
          category="Product line specific"
          isDisabled={hasNoRx}
          options={productLineOptions}
          setValue={setValue}
          value={productIdentifier}
        />
        <SelectorComponent
          category="Sphere (SPH)"
          isDisabled={hasNoRx}
          options={sphereValues}
          setValue={setValue}
          value={sphere}
        />
        <SelectorComponent
          category="Cylinder (CYL)"
          isDisabled={hasNoRx}
          options={cylinderValues}
          setValue={setValue}
          value={cylinder}
        />
        <SelectorComponent
          category="Axis"
          isDisabled={hasNoRx}
          options={axisValues}
          setValue={setValue}
          value={axis}
        />
        <SelectorComponent
          category="ADD"
          isDisabled={hasNoRx}
          options={additionValues}
          setValue={setValue}
          value={addition}
        />
        <SelectorComponent
          category="Base Curve (BC)"
          isDisabled={hasNoRx}
          options={baseCurveValues}
          setValue={setValue}
          value={baseCurve}
        />
        <SelectorComponent
          category="Diameter (DIA)"
          isDisabled={hasNoRx}
          options={diameterValues}
          setValue={setValue}
          value={diameter}
        />
        <SelectorComponent
          category="Color"
          isDisabled={hasNoRx}
          options={colorValues}
          setValue={setValue}
          value={color}
        />
      </Grid>
      {isOriginalRxProduct && (
        <Grid className={lowerComponent} id="productDetails">
          <Typography
            className={numberOfLenses}
            id="numberOfLenses"
            variant="body1"
          >
            Number of Lenses: {quantityDescription}
          </Typography>
          <Typography
            className={productInformation}
            id="furtherInfo"
            variant="body1"
          >
            Product Details
          </Typography>

          <ul className={list}>
            <li id="material" className={infoItem}>
              Material: {material}
            </li>
            <li id="waterContent" className={infoItem}>
              Water Content: {waterContent}%
            </li>
            <li id="oxygenPermeability" className={infoItem}>
              Oxygen Permeability: {oxygenPermeability} Dk/t
            </li>
          </ul>
        </Grid>
      )}
    </Grid>
  );
}

export default ProductInformationComponent;

import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "15px",
  },
  link: {
    color: "#00A2E1",
    fontWeight: 600,
    textDecoration: "none",
  },
  questions: {
    fontSize: 18,
    fontFamily: "proxima-nova",
    lineHeight: "28px",
    width: 650,
  },
  phone: {
    fontWeight: 600,
  },
}));

function QuestionsComponent() {
  const { container, link, questions, phone } = useStyles();

  const emailLink = (
    <a
      className={link}
      href="mailto:contacts@warbyparker.com"
      rel="noopener noreferrer"
      target="_blank"
    >
      contacts@warbyparker.com
    </a>
  );

  return (
    <Grid className={container} item>
      <Typography id="questions" variant="body2" className={questions}>
        Questions? Call us at <span className={phone}>888.492.7297</span>, any
        day, 9 a.m.–10 p.m. ET. If you’re having trouble submitting information
        through the portal, email {emailLink} for help submitting your response.
        Thank you!
      </Typography>
    </Grid>
  );
}

export default QuestionsComponent;

import {
  thunkOn,
  ThunkOn,
  Action,
  action,
  Computed,
  computed,
} from "easy-peasy";
import { StoreModel } from "store";
import get from "lodash.get";

interface Actions {
  [storeName: string]: {
    [actionType: string]: any;
  };
}

export type StatusTypes = undefined | "start" | "success" | "fail";

export function isTargetActionType(action: string) {
  if (action.includes("@thunk")) {
    return (
      action.includes("(start)") ||
      action.includes("(success)") ||
      action.includes("(fail)")
    );
  }
  return false;
}

// helper method so listener triggers on each step of the action
export function getThunkActions(storeActions: Actions) {
  const actions: string[] = [];
  Object.keys(storeActions).forEach(storeName => {
    const store = storeActions[storeName];
    Object.keys(store).forEach(actionKey => {
      const actionObject = store[actionKey];
      Object.keys(actionObject).forEach(actionObjectProps => {
        const action = actionObject[actionObjectProps];
        if (isTargetActionType(action)) {
          actions.push(action);
        }
      });
    });
  });
  return actions;
}

export function getActionType(action: string) {
  if (action.includes("(start)")) return "start";
  if (action.includes("(success)")) return "success";
  if (action.includes("(fail)")) return "fail";
  return "default";
}

export interface ActionStatus {
  lastActionType: string;
  payload: any;
  result?: any;
}

interface Entries {
  [storeName: string]: {
    [action: string]: ActionStatus;
  };
}

interface ResetStoreActionPayload {
  storeName: string;
  action: string;
}

interface AddDataPayload {
  actionName: string;
  actionType: string;
  payload: any;
  result?: any;
  storeName: string;
}

export interface Status {
  addEntry: Action<Status, AddDataPayload>;
  entries: Entries;
  getLastActionType: Computed<
    Status,
    (storeName: string, actionName: string) => string
  >;
  isLoading: Computed<
    Status,
    (storeName: string, actionName: string) => boolean
  >;
  onThunk: ThunkOn<Status, void, StoreModel>;
  resetStoreAction: Action<Status, ResetStoreActionPayload>;
}

const statusModel: Status = {
  addEntry: action(
    (state, { storeName, actionName, actionType, payload, result }) => {
      if (!state.entries[storeName]) state.entries[storeName] = {};
      state.entries[storeName][actionName] = {
        payload,
        lastActionType: actionType,
        result,
      };
    },
  ),
  entries: {},
  getLastActionType: computed(state => (storeName, actionName) => {
    return get(state, ["entries", storeName, actionName, "lastActionType"]);
  }),
  isLoading: computed(state => (...props) => {
    const lastActionType = state.getLastActionType(...props);
    return lastActionType === "start";
  }),
  onThunk: thunkOn(
    (_actions, storeActions) => {
      return getThunkActions(storeActions);
    },
    async (actions, target) => {
      const action = target.type;
      const [, storeName, fullActionName] = action.split(".");
      const [actionName] = fullActionName.split("(");
      const actionType = getActionType(action);

      return actions.addEntry({
        storeName,
        actionType,
        actionName,
        payload: target.payload,
        result: target.result,
      });
    },
  ),
  resetStoreAction: action((state, { storeName, action }) => {
    if (state.entries[storeName][action]) {
      state.entries[storeName][action].lastActionType = "reset";
    }
  }),
};

export default statusModel;

import { Thunk, thunk } from "easy-peasy";

import { Injections, StoreModel } from "store";
import { get } from "lodash";
import logger from "logger";

export interface AuthPayload {
  prescriptionRequestId: number;
  token: string;
}

export interface Auth {
  login: Thunk<Auth, AuthPayload, Injections, StoreModel>;
}

const authModel: Auth = {
  login: thunk(
    async (
      _actions,
      { prescriptionRequestId, token },
      { injections: { api }, getStoreActions },
    ) => {
      const loginData = await api.login({
        token,
        prescriptionRequestId,
      });

      // we expect a status when its expired or already been submitted
      const loginStatus = get(loginData, "status", "");
      if (!!loginStatus) {
        // we differentiate between the two for navigation purposes on login page
        if (loginStatus === "expired") {
          logger.info(
            { app: "rx-verification-portal" },
            "Login failure: prescription request expired",
          );
          return Promise.resolve(loginData);
        } else {
          logger.info(
            { app: "rx-verification-portal" },
            "Login failure: prescription request already submitted",
          );
          return Promise.reject();
        }
      }

      logger.info({ app: "rx-verification-portal" }, "Login success");
      getStoreActions().prescription.add({
        loginData,
        prescriptionRequestId,
      });
    },
  ),
};

export default authModel;

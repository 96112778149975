import { Action, action, Computed, computed, Thunk, thunk } from "easy-peasy";
import { get, merge } from "lodash";
import { navigate } from "@reach/router";
import moment, { Moment } from "moment";

import { Injections, StoreModel } from "store";
import { ProductRxValuesByProductIdentifier } from "./products";

export function getSubmissionEyeValue(
  formEyeValues: FormEyeValues,
  key: string,
) {
  if (formEyeValues.hasNoRx) return null;
  return get(formEyeValues, key);
}

export const submissionDataIsValid = (
  submissionData: SubmissionData,
  validRxConfigurations: ProductRxValuesByProductIdentifier,
) => {
  let odIsValid = false;
  let osIsValid = false;
  const odProductId = submissionData.od_contact_lens_product_identifier;
  const osProductId = submissionData.os_contact_lens_product_identifier;

  if (!odProductId) {
    odIsValid = true;
  } else {
    const odValidRxConfigurations = validRxConfigurations[odProductId];

    if (odValidRxConfigurations) {
      odIsValid = odValidRxConfigurations.some(
        odValidRx =>
          String(submissionData.od_add) === String(odValidRx.add) &&
          String(submissionData.od_axis) === String(odValidRx.axis) &&
          String(submissionData.od_cyl) === String(odValidRx.cyl) &&
          String(submissionData.od_contact_lens_base_curve) ===
            String(odValidRx.baseCurve) &&
          String(submissionData.od_contact_lens_diameter) ===
            String(odValidRx.diameter) &&
          String(submissionData.od_contact_lens_color) ===
            String(odValidRx.color) &&
          String(submissionData.od_sph) === String(odValidRx.power),
      );
    }
  }

  if (!osProductId) {
    osIsValid = true;
  } else {
    const osValidRxConfigurations = validRxConfigurations[osProductId];

    if (osValidRxConfigurations) {
      osIsValid = osValidRxConfigurations.some(
        osValidRx =>
          String(submissionData.os_add) === String(osValidRx.add) &&
          String(submissionData.os_axis) === String(osValidRx.axis) &&
          String(submissionData.os_cyl) === String(osValidRx.cyl) &&
          String(submissionData.os_contact_lens_base_curve) ===
            String(osValidRx.baseCurve) &&
          String(submissionData.os_contact_lens_diameter) ===
            String(osValidRx.diameter) &&
          String(submissionData.os_contact_lens_color) ===
            String(osValidRx.color) &&
          String(submissionData.os_sph) === String(osValidRx.power),
      );
    }
  }

  return odIsValid && osIsValid;
};

interface AddPayload {
  loginData: LoginData;
  prescriptionRequestId: number;
}

export interface LoginDataWithRxId extends LoginData {
  prescriptionRequestId: number;
}

export interface LoginData {
  dateOfRequest: string;
  expires: string | null;
  eyes: EyeValues[];
  ohmJwt: string;
  patient: PatientInfo;
  salesOrderCreated: string;
  salesOrderId: number;
}

export interface EyeValues {
  addition: string;
  axis: string;
  baseCurve: string;
  color: string;
  cylinder: string;
  diameter: string;
  eye: string;
  material: string;
  oxygenPermeability: string;
  productIdentifier: string;
  quantityDescription: string;
  sphere: string;
  waterContent: string;
}

export interface ValuesByEye {
  [eye: string]: EyeValues;
}

export interface FormEyeValues {
  addition: string;
  axis: string | number;
  baseCurve: string;
  color: string;
  cylinder: string;
  diameter: string;
  hasNoRx: boolean;
  sphere: string;
  productIdentifier: string;
}

export interface FormData {
  expirationDate: Moment;
  hasRequestedScoutTrial: boolean;
  os: FormEyeValues;
  od: FormEyeValues;
  oneTimeUse: boolean;
}

export interface SubmissionData {
  expiration_date: string | null;
  interested_in_scout_trials: boolean;
  od_sph: string | null;
  od_cyl: string | null;
  od_axis: string | number | null;
  od_add: string | null;
  od_contact_lens_base_curve: string | null;
  od_contact_lens_diameter: string | null;
  od_contact_lens_color: string | null;
  od_contact_lens_product_identifier: string | null;
  os_sph: string | null;
  os_cyl: string | null;
  os_axis: string | number | null;
  os_add: string | null;
  os_contact_lens_base_curve: string | null;
  os_contact_lens_diameter: string | null;
  os_contact_lens_color: string | null;
  os_contact_lens_product_identifier: string | null;
  prescription_usage_key: string;
}

export interface OrderInfo {
  dateOfRequest: string;
  expires: string | null;
  prescriptionRequestId: number;
  salesOrderCreated: string;
  salesOrderId: number;
}

export interface PatientInfo {
  extendedAddress: string | null;
  locality: string;
  patientName: string;
  postalCode: string;
  region: string;
  streetAddress: string;
}

export type RejectionKey = "rx_expired" | "rx_not_on_file";

const defaultFormEyeValues: FormEyeValues = {
  addition: "",
  axis: "",
  baseCurve: "",
  color: "",
  cylinder: "",
  diameter: "",
  hasNoRx: false,
  sphere: "",
  productIdentifier: "",
};

export const defaultFormExpirationDate = moment().add(1, "year");

export interface Prescription {
  add: Action<Prescription, AddPayload>;
  data: LoginDataWithRxId | undefined;
  formData: FormData;
  initializeFormData: Action<Prescription, void>;
  orderInfo: Computed<Prescription, OrderInfo>;
  patientInfo: Computed<Prescription, PatientInfo>;
  reject: Thunk<Prescription, RejectionKey, Injections>;
  resetData: Action<Prescription>;
  submissionData: Computed<Prescription, SubmissionData>;
  submit: Thunk<Prescription, void, Injections, StoreModel>;
  updateFormData: Action<Prescription, Partial<FormData>>;
  valuesByEye: Computed<Prescription, ValuesByEye>;
}

const prescriptionModel: Prescription = {
  add: action((state, { loginData, prescriptionRequestId }) => {
    const loginDataWithRxId: LoginDataWithRxId = {
      ...loginData,
      prescriptionRequestId,
    };
    state.data = loginDataWithRxId;
  }),
  data: undefined,
  formData: {
    expirationDate: defaultFormExpirationDate,
    hasRequestedScoutTrial: false,
    os: defaultFormEyeValues,
    od: defaultFormEyeValues,
    oneTimeUse: false,
  },
  initializeFormData: action(state => {
    const {
      valuesByEye: { os, od },
    } = state;
    if (!state.data) return;

    state.formData = {
      expirationDate: defaultFormExpirationDate,
      hasRequestedScoutTrial: false,
      os: {
        addition: os.addition,
        axis: os.axis,
        baseCurve: os.baseCurve,
        color: os.color,
        cylinder: os.cylinder,
        diameter: os.diameter,
        hasNoRx: false,
        sphere: os.sphere,
        productIdentifier: os.productIdentifier,
      },
      od: {
        addition: od.addition,
        axis: od.axis,
        baseCurve: od.baseCurve,
        color: od.color,
        cylinder: od.cylinder,
        hasNoRx: false,
        diameter: od.diameter,
        sphere: od.sphere,
        productIdentifier: od.productIdentifier,
      },
      oneTimeUse: false,
    };
  }),
  orderInfo: computed(state => {
    const prescription = state.data || {};

    return {
      dateOfRequest: get(prescription, "dateOfRequest", ""),
      expires: get(prescription, "expires", ""),
      prescriptionRequestId: get(prescription, "prescriptionRequestId", -1),
      salesOrderCreated: get(prescription, "salesOrderCreated", ""),
      salesOrderId: get(prescription, "salesOrderId", -1),
    };
  }),
  patientInfo: computed(state => {
    const prescription = state.data || {};

    return {
      addressId: get(prescription, "patient.addressId", -1),
      extendedAddress: get(prescription, "patient.extendedAddress", ""),
      locality: get(prescription, "patient.locality", ""),
      patientName: get(prescription, "patient.patientName", ""),
      postalCode: get(prescription, "patient.postalCode", ""),
      region: get(prescription, "patient.region", ""),
      streetAddress: get(prescription, "patient.streetAddress", ""),
    };
  }),
  reject: thunk(
    async (_actions, rejectionKey, { getState, injections: { api } }) => {
      const hasRequestedScoutTrial = getState().formData.hasRequestedScoutTrial;

      await api.rejectRxRequest(rejectionKey, hasRequestedScoutTrial);

      navigate("/thanks");
    },
  ),
  submissionData: computed(({ formData }) => {
    const prescriptionUsageKey = formData.oneTimeUse
      ? "single_use"
      : "valid_until_expiration";

    const expirationDate = formData.oneTimeUse
      ? null
      : formData.expirationDate.format("YYYY-MM-DD");

    return {
      expiration_date: expirationDate,
      interested_in_scout_trials: formData.hasRequestedScoutTrial,
      od_add: getSubmissionEyeValue(formData.od, "addition"),
      od_axis: getSubmissionEyeValue(formData.od, "axis"),
      od_cyl: getSubmissionEyeValue(formData.od, "cylinder"),
      od_contact_lens_base_curve: getSubmissionEyeValue(
        formData.od,
        "baseCurve",
      ),
      od_contact_lens_diameter: getSubmissionEyeValue(formData.od, "diameter"),
      od_contact_lens_color: getSubmissionEyeValue(formData.od, "color"),
      od_contact_lens_product_identifier: getSubmissionEyeValue(
        formData.od,
        "productIdentifier",
      ),
      od_sph: getSubmissionEyeValue(formData.od, "sphere"),
      os_add: getSubmissionEyeValue(formData.os, "addition"),
      os_axis: getSubmissionEyeValue(formData.os, "axis"),
      os_cyl: getSubmissionEyeValue(formData.os, "cylinder"),
      os_contact_lens_base_curve: getSubmissionEyeValue(
        formData.os,
        "baseCurve",
      ),
      os_contact_lens_diameter: getSubmissionEyeValue(formData.os, "diameter"),
      os_contact_lens_color: getSubmissionEyeValue(formData.os, "color"),
      os_contact_lens_product_identifier: getSubmissionEyeValue(
        formData.os,
        "productIdentifier",
      ),
      os_sph: getSubmissionEyeValue(formData.os, "sphere"),
      prescription_usage_key: prescriptionUsageKey,
    };
  }),
  submit: thunk(
    async (
      actions,
      _payload,
      { getState, getStoreState, injections: { api } },
    ) => {
      const formData = getState().formData;
      const rxIsExpired = formData.expirationDate.isBefore(
        moment().startOf("day"),
      );
      const rxIsSingleUse = formData.oneTimeUse;

      if (rxIsExpired && !rxIsSingleUse) {
        return actions.reject("rx_expired");
      }

      if (formData.od.hasNoRx && formData.os.hasNoRx) {
        return actions.reject("rx_not_on_file");
      }

      const submissionData = getState().submissionData;

      const validRxConfigurations = getStoreState().products
        .productRxValuesByProductIdentifier;

      if (!submissionDataIsValid(submissionData, validRxConfigurations)) {
        return Promise.reject();
      }

      await api.submitForm(submissionData);

      navigate("/thanks");
    },
  ),
  resetData: action(state => (state.data = undefined)),
  updateFormData: action((state, formDataUpdate) => {
    state.formData = merge({}, state.formData, formDataUpdate);
  }),
  valuesByEye: computed(state => {
    const valuesByEye: ValuesByEye = {};
    const eyes = (state.data && state.data.eyes) || [];

    eyes.forEach(eyeValues => (valuesByEye[eyeValues.eye] = eyeValues));
    return valuesByEye;
  }),
};

export default prescriptionModel;

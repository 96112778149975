import React from "react";
import { Typography, makeStyles, Container } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  body: {
    marginTop: "20px",
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
    width: 650,
  },
  container: {
    padding: "50px 100px",
  },
  link: {
    color: "#00A2E1",
    fontWeight: 600,
    textDecoration: "none",
  },
  phone: {
    fontWeight: 600,
  },
  title: {
    fontFamily: "utopia-std-display, serif",
    fontSize: 40,
    lineHeight: "44px",
    paddingTop: 150,
    width: 650,
  },
}));

interface Props {
  dateOfRequest: string;
}

function RequestExpiredComponent({ dateOfRequest }: Props) {
  const { body, container, link, title } = useStyles();

  const emailLink = (
    <a
      className={link}
      href="mailto:contacts@warbyparker.com"
      rel="noopener noreferrer"
      target="_blank"
    >
      contacts@warbyparker.com
    </a>
  );

  return (
    <Container className={container}>
      <Typography id="header" variant="h4" className={title}>
        Oops! The prescription verification window has closed.
      </Typography>
      <Typography className={body} id="body" variant="body1">
        The verification request expired eight business hours after our initial
        fax on {dateOfRequest}. Questions? Call us at{" "}
        <span className="phone">888.492.7297</span>, any day, 9 a.m.–10 p.m. ET
        or email us at {emailLink}.
      </Typography>
    </Container>
  );
}

export default RequestExpiredComponent;

import React, { useState } from "react";
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import QuestionsComponent from "Components/QuestionsComponent";
import { AuthPayload } from "store/auth";

const useStyles = makeStyles(theme => ({
  button: {
    width: "50%",
    padding: "12px 17px",
    marginTop: 12,
    borderRadius: 1,
    boxShadow: "none",
    textTransform: "capitalize",
  },
  errorText: {
    padding: "12px 16px",
    borderRadius: 2,
    fontFamily: "proxima-nova",
    fontSize: 16,
    lineHeight: "26px",
    color: "#414B56",
    border: "1px solid #D6003C",
    backgroundColor: "#FFEDEB",
    width: 616,
  },
  errorWrapper: {
    marginBottom: "2px",
  },
  gridItem: {
    maxWidth: "600px",
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
  gridItemInput: {
    padding: "0 8px !important",
  },
  textFieldStyle: {
    borderRadius: 3,
    width: 650,
  },
  textFieldStylePresc: {
    borderRadius: 3,
    marginBottom: 10,
    width: 650,
  },
  title: {
    fontFamily: "utopia-std-display, serif",
    fontSize: 40,
    lineHeight: "44px",
  },
  rootContainer: {
    marginLeft: "28vw",
    paddingTop: "20vh",
    maxWidth: "700px",
    backgroundColor: "#F9F9F9",
  },
  [theme.breakpoints.down("lg")]: {
    rootContainer: {
      marginLeft: "21vw",
    },
  },
  [theme.breakpoints.down("md")]: {
    rootContainer: {
      marginLeft: "15vw",
    },
  },
  [theme.breakpoints.down("sm")]: {
    gridItem: {
      maxWidth: "450px",
    },
    rootContainer: {
      marginLeft: "8vw",
      maxWidth: "550px",
    },
  },
  [theme.breakpoints.down("xs")]: {
    gridItem: {
      maxWidth: "250px",
    },
    rootContainer: {
      maxWidth: "350px",
    },
  },
}));

const notNumbersRegex = /[^0-9]/g;

interface Props {
  hasLoginFailed: boolean;
  login: ({ prescriptionRequestId, token }: AuthPayload) => void;
}

function LoginComponent({ hasLoginFailed, login }: Props) {
  const {
    button,
    errorText,
    errorWrapper,
    gridItem,
    rootContainer,
    textFieldStyle,
    title,
    textFieldStylePresc,
    gridItemInput,
  } = useStyles();

  const [prescriptionId, setPrescriptionId] = useState<string | undefined>(
    undefined,
  );

  const [token, setToken] = useState("");

  function handlePrescriptionIdChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    // strip textInput of all non 0-9 characters
    const textInput = event.target.value.replace(notNumbersRegex, "");
    setPrescriptionId(textInput);
  }

  function handleTokenChange(event: React.ChangeEvent<HTMLInputElement>) {
    setToken(event.target.value);
  }

  const isLoginDisabled = !prescriptionId || !token;
  const loginHandler = () => {
    if (!isLoginDisabled)
      login({ prescriptionRequestId: Number(prescriptionId), token });
  };

  return (
    <Grid className={rootContainer} container direction="column" spacing={2}>
      <Grid item>
        <Typography id="welcome" variant="h4" className={title}>
          Welcome to the Warby Parker prescription verification portal.
        </Typography>
      </Grid>
      {hasLoginFailed && (
        <Grid className={errorWrapper} item>
          <Typography className={errorText} id="loginError" variant="body2">
            Your log-in credentials may be invalid or the request has expired
            (3+ business days have passed). Please try again.
          </Typography>
        </Grid>
      )}
      <Grid className={gridItemInput} item>
        <TextField
          fullWidth
          id="rx-request-id"
          onChange={handlePrescriptionIdChange}
          placeholder="Prescription ID"
          type="string"
          value={prescriptionId}
          variant="outlined"
          className={textFieldStylePresc}
        />
      </Grid>
      <Grid className={gridItemInput} item>
        <TextField
          fullWidth
          id="token"
          onChange={handleTokenChange}
          placeholder="Log-in Token"
          type="string"
          value={token}
          variant="outlined"
          className={textFieldStyle}
        />
      </Grid>
      <Grid className={gridItem} item>
        <Button
          className={button}
          color="primary"
          disabled={isLoginDisabled}
          onClick={loginHandler}
          type="submit"
          variant="contained"
        >
          Submit
        </Button>
      </Grid>
      <QuestionsComponent />
    </Grid>
  );
}

export default LoginComponent;

import React, { useEffect } from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

import { FlattenedProductLineData } from "store/products";
import isObject from "lodash.isobject";

const useStyles = makeStyles(() => ({
  item: {
    fontWeight: 600,
  },
  labelFont: {
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
    paddingLeft: 15,
  },
  productLine: {
    color: "#414b56",
    fontFamily: "proxima-nova",
    fontWeight: 600,
    opacity: 1,
  },
  productSpecificLine: {
    color: "#414b56",
    fontFamily: "proxima-nova",
    fontWeight: 600,
    paddingLeft: 30,
  },
  selector: {
    backgroundColor: "#ffff",
    padding: "5px 20px",
    border: "1px solid #f1f1f1",
    boxShadow:
      "0 6px 12px 0 rgba(239, 243, 245, 0.48), 0 1px 4px 0 rgba(228, 230, 232, 0.6)",
    borderRadius: 3,
    marginBottom: 10,
  },
}));

export function isOptionDisabled(option: PossibleOption) {
  return isObject(option) && !option.productSpecificLineId;
}

export function getValue(option: PossibleOption) {
  if (isObject(option)) {
    return option.productSpecificLineId || option.name;
  }
  if (option === null) return undefined;
  return option;
}

export function getText(option: PossibleOption) {
  if (isObject(option)) return option.name;
  if (option === null) return "None";
  return option;
}

export type Category =
  | "ADD"
  | "Axis"
  | "Base Curve (BC)"
  | "Product line specific"
  | "Color"
  | "Cylinder (CYL)"
  | "Diameter (DIA)"
  | "Sphere (SPH)";
type PossibleOption = FlattenedProductLineData | string | number | null;

interface Props {
  category: Category;
  isDisabled: boolean;
  options: (FlattenedProductLineData | string | number)[];
  setValue: (category: Category, value: string | number) => void;
  value: string | number;
}

function SelectorComponent({
  category,
  isDisabled,
  options,
  setValue,
  value,
}: Props) {
  const {
    item,
    labelFont,
    productLine,
    productSpecificLine,
    selector,
  } = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(category, event.target.value as string);
  };

  useEffect(() => {
    if (options.length === 1) {
      const optionValue = getValue(options[0]);
      if (optionValue !== undefined && optionValue !== value) {
        setValue(category, optionValue);
      }
    }
  }, [category, options, setValue, value]);

  // if there is only one option there is no need to render the selector
  // since there is no selection to be made. We already autoselect it above
  const alwaysRender =
    category === "Base Curve (BC)" || category === "Diameter (DIA)";
  if (options.length === 1 && !alwaysRender) return null;

  const hasOptions = options.length > 0;

  return (
    <FormControl className={selector} disabled={isDisabled}>
      <InputLabel shrink={true} className={labelFont}>
        {category}
      </InputLabel>
      <Select
        autoWidth
        displayEmpty
        disableUnderline
        inputProps={{ id: "brand-selector" }}
        onChange={handleChange}
        value={value}
      >
        {hasOptions &&
          options.map((option: PossibleOption, index: number) => {
            const isDisabled = isOptionDisabled(option);

            return (
              <MenuItem
                className={isDisabled ? productLine : productSpecificLine}
                disabled={isDisabled}
                key={index}
                value={getValue(option)}
              >
                <span className={item}>{getText(option)}</span>
              </MenuItem>
            );
          })}
        {!hasOptions && (
          <MenuItem value={value}>
            <span className={item} id="initialValue">
              {value}
            </span>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export default SelectorComponent;

import React from "react";
import { Typography, makeStyles, Container } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  body: {
    marginTop: "20px",
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
    width: 650,
  },
  container: {
    padding: "50px 100px",
  },
  link: {
    color: "#00A2E1",
    fontWeight: 600,
    textDecoration: "none",
  },
  phone: {
    fontWeight: 600,
  },
  title: {
    fontFamily: "utopia-std-display, serif",
    fontSize: 40,
    lineHeight: "44px",
    paddingTop: 150,
  },
}));

function ThankYouComponent() {
  const { body, container, link, phone, title } = useStyles();

  const warbyLink = (
    <a className={link} href="https://www.warbyparker.com">
      www.warbyparker.com
    </a>
  );

  return (
    <Container className={container}>
      <Typography id="header" variant="h4" className={title}>
        Thank you!
      </Typography>
      <Typography className={body} id="body" variant="body1">
        Thank you for using the Warby Parker prescription verification portal.
        If you have any questions or would like to learn more about our contact
        lens offerings, please visit {warbyLink} or call us at{" "}
        <span className={phone}>888.492.7297</span>, any day, 9 a.m.–10 p.m. ET.
      </Typography>
    </Container>
  );
}

export default ThankYouComponent;

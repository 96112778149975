import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";
import { ThemeProvider } from "@material-ui/styles";
import { LocationProvider } from "@reach/router";

import Routes from "Features/Routes";
import store from "./store";
import theme from "./theme";

ReactDOM.render(
  <StoreProvider store={store}>
    <ThemeProvider theme={theme}>
      <LocationProvider>
        <Routes />
      </LocationProvider>
    </ThemeProvider>
  </StoreProvider>,
  document.getElementById("root"),
);

import { Action, action, Computed, computed, Thunk, thunk } from "easy-peasy";

import { Injections } from "store";

export function sortProductSpecificLines(
  productSpecificLines: ProductSpecificLine[],
) {
  return productSpecificLines.sort((a, b) => {
    const nameA = Object.keys(a)[0];
    const nameB = Object.keys(b)[0];
    return nameA.localeCompare(nameB);
  });
}

export function flattenProductSpecificLines(
  productSpecificLines: ProductSpecificLine[],
) {
  return productSpecificLines.map(productSpecificLineObject => {
    const name = Object.keys(productSpecificLineObject)[0];
    const productSpecificLine = productSpecificLineObject[name];

    return {
      name,
      pcProductId: productSpecificLine.pc_product_id,
      productSpecificLineId: productSpecificLine.product_specific_line_id,
    };
  });
}

export function filterProductSpecificLines(
  productSpecificLines: FlattenedProductSpecificLine[],
) {
  const existingProductSpecificLineIds: string[] = [];
  return productSpecificLines.filter(({ productSpecificLineId }) => {
    if (existingProductSpecificLineIds.includes(productSpecificLineId))
      return false;

    existingProductSpecificLineIds.push(productSpecificLineId);
    return true;
  });
}

interface AddProductRxValuesByProductIdentifierPayload {
  productIdentifier: string;
  productRxValues: ProductRxValue[];
}

interface FlattenedBrand {
  name: string;
  pcProductId: undefined;
  productSpecificLineId: undefined;
}

interface FlattenedProductSpecificLine {
  name: string;
  pcProductId: number;
  productSpecificLineId: string;
}

export type FlattenedProductLineData =
  | FlattenedBrand
  | FlattenedProductSpecificLine;

export interface ProductSpecificLine {
  [productSpecificLineName: string]: {
    pc_product_id: number;
    product_specific_line_id: string;
  };
}

export interface ProductLinesRow {
  brandName: string;
  productSpecificLines: ProductSpecificLine[];
}

export interface ProductRxValue {
  add: string;
  axis: number;
  baseCurve: string;
  color: string;
  cyl: string;
  diameter: string;
  power: string;
}

export interface ProductRxValuesByProductIdentifier {
  [productIdentifier: string]: ProductRxValue[];
}

export interface UniqueRxValues {
  add: string[];
  axis: number[];
  baseCurve: string[];
  color: string[];
  cyl: string[];
  diameter: string[];
  power: string[];
  // workaround to avoid issue when using Object.keys()
  [key: string]: string[] | number[];
}

export interface RxValuesByProductIdentifier {
  [productIdentifier: string]: UniqueRxValues;
}

export interface Products {
  addProductLines: Action<Products, ProductLinesRow[]>;
  addProductRxValuesByProductIdentifier: Action<
    Products,
    AddProductRxValuesByProductIdentifierPayload
  >;
  flattenedProductLines: Computed<Products, FlattenedProductLineData[]>;
  getProductLines: Thunk<Products, void, Injections>;
  getProductRxValues: Thunk<Products, string, Injections>;
  isScout: Computed<Products, (productIdentifier: string) => boolean>;
  rxValuesByProductIdentifier: Computed<Products, RxValuesByProductIdentifier>;
  productLinesData: ProductLinesRow[];
  productRxValuesByProductIdentifier: ProductRxValuesByProductIdentifier;
  productIdentifiersPcProductIds: Computed<
    Products,
    (productIdentifier: string) => number[]
  >;
}

const productsModel: Products = {
  addProductLines: action((state, ProductLines) => {
    state.productLinesData = ProductLines;
  }),
  addProductRxValuesByProductIdentifier: action(
    (state, { productIdentifier, productRxValues }) => {
      state.productRxValuesByProductIdentifier[
        productIdentifier
      ] = productRxValues;
    },
  ),
  flattenedProductLines: computed(state => {
    const flattenedProductLines: FlattenedProductLineData[] = [];

    return state.productLinesData.reduce((flattenedData, productLine) => {
      flattenedData.push({
        name: productLine.brandName,
        pcProductId: undefined,
        productSpecificLineId: undefined,
      });

      const sortedProductSpecificLines = sortProductSpecificLines(
        productLine.productSpecificLines,
      );

      const flattenedProductSpecificLines = flattenProductSpecificLines(
        sortedProductSpecificLines,
      );

      const flattenedFilteredProductSpecificLines = filterProductSpecificLines(
        flattenedProductSpecificLines,
      );

      return [...flattenedData, ...flattenedFilteredProductSpecificLines];
    }, flattenedProductLines);
  }),
  getProductLines: thunk(async (actions, _payload, { injections: { api } }) => {
    const productLines = await api.getProductLines();

    actions.addProductLines(productLines);
  }),
  getProductRxValues: thunk(
    async (actions, productIdentifier, { getState, injections: { api } }) => {
      const pcProductIds = getState().productIdentifiersPcProductIds(
        productIdentifier,
      );
      const productRxValues = await api.getProductRxValues(pcProductIds);
      actions.addProductRxValuesByProductIdentifier({
        productIdentifier,
        productRxValues,
      });
    },
  ),
  isScout: computed(state => productIdentifier => {
    return state.flattenedProductLines.some(
      ({ name, productSpecificLineId }) => {
        return (
          String(productIdentifier) === String(productSpecificLineId) &&
          name.includes("Scout")
        );
      },
    );
  }),
  productIdentifiersPcProductIds: computed(state => productIdentifier => {
    const pcProductIds: number[] = [];

    state.productLinesData.forEach(productLine => {
      productLine.productSpecificLines.forEach(productSpecificLine => {
        const name = Object.keys(productSpecificLine)[0];
        const { pc_product_id, product_specific_line_id } = productSpecificLine[
          name
        ];

        if (
          product_specific_line_id === String(productIdentifier) &&
          pc_product_id !== undefined
        ) {
          pcProductIds.push(pc_product_id);
        }
      });
    });

    return pcProductIds;
  }),
  productLinesData: [],
  productRxValuesByProductIdentifier: {},
  rxValuesByProductIdentifier: computed(state => {
    const rxValuesByProductIdentifier: RxValuesByProductIdentifier = {};
    Object.keys(state.productRxValuesByProductIdentifier).forEach(
      productIdentifier => {
        const uniqueRxValues: UniqueRxValues = {
          add: [],
          axis: [],
          baseCurve: [],
          color: [],
          cyl: [],
          diameter: [],
          power: [],
        };
        const allSetsOfValues =
          state.productRxValuesByProductIdentifier[productIdentifier];
        // forgive our coding sins - we were on a time crunch
        allSetsOfValues.forEach(
          ({ add, axis, baseCurve, color, cyl, diameter, power }) => {
            if (!uniqueRxValues.add.includes(add)) uniqueRxValues.add.push(add);
            if (!uniqueRxValues.axis.includes(axis))
              uniqueRxValues.axis.push(axis);
            if (!uniqueRxValues.baseCurve.includes(baseCurve))
              uniqueRxValues.baseCurve.push(baseCurve);
            if (!uniqueRxValues.color.includes(color))
              uniqueRxValues.color.push(color);
            if (!uniqueRxValues.cyl.includes(cyl)) uniqueRxValues.cyl.push(cyl);
            if (!uniqueRxValues.diameter.includes(diameter))
              uniqueRxValues.diameter.push(diameter);
            if (!uniqueRxValues.power.includes(power))
              uniqueRxValues.power.push(power);
          },
        );

        Object.keys(uniqueRxValues).forEach(key => {
          const possibleValues = uniqueRxValues[key];
          possibleValues.sort((a: any, b: any) => {
            if (isNaN(Number(a))) return a.localeCompare(b);
            return Number(a) > Number(b) ? 1 : -1;
          });
        });

        rxValuesByProductIdentifier[productIdentifier] = uniqueRxValues;
      },
    );
    return rxValuesByProductIdentifier;
  }),
};

export default productsModel;

import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import moment from "moment";

import { OrderInfo, PatientInfo } from "store/prescription";
import { formatFullAddress } from "../../../store/helpers";

interface Props {
  orderInfo: OrderInfo;
  patientInfo: PatientInfo;
}

const useStyles = makeStyles(theme => ({
  dateOfOrder_: {
    paddingBottom: 5,
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
  masterGrid: {
    margin: "40px 0",
  },
  patientInfoTitle: {
    color: "#414B56",
    fontFamily: "proxima-nova",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "28px",
    marginBottom: 10,
    marginTop: 20,
    textAlign: "left",
  },
  patientInfobox: {
    width: "fit-content",
    padding: 0,
  },
  patientAddress: {
    whiteSpace: "pre-wrap",
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
  patient: {
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
  salesOrder: {
    width: "fit-content",
  },
  salesOrderId: {
    paddingBottom: 5,
    fontFamily: "proxima-nova",
    fontSize: 18,
    lineHeight: "28px",
  },
}));

function PatientInformationComponent({ orderInfo, patientInfo }: Props) {
  const {
    masterGrid,
    patientInfoTitle,
    patientInfobox,
    dateOfOrder_,
    patientAddress,
    salesOrder,
    salesOrderId,
    patient,
  } = useStyles();
  const dateOfOrder = moment(orderInfo.salesOrderCreated).format("MM/DD/YYYY");

  return (
    <div>
      <Grid container direction="column" className={masterGrid}>
        <Grid container direction="row">
          <Grid item xs={12} sm={12}>
            <Typography id="title" variant="body1" className={patientInfoTitle}>
              Patient Information
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={patientInfobox}>
              <Typography
                id="dateOfOrder"
                variant="body1"
                className={dateOfOrder_}
              >
                <span>Date of Order:</span>
                {` ${dateOfOrder}`}
              </Typography>
              <Typography id="patientName" variant="body1" className={patient}>
                <span>Patient Name:</span>
                {` ${patientInfo.patientName}`}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={salesOrder}>
              <Typography
                id="salesOrderId"
                variant="body1"
                className={salesOrderId}
              >
                <span>Warby Parker Order #:</span> {orderInfo.salesOrderId}
              </Typography>
              <Typography
                id="patientAddress"
                variant="body1"
                className={patientAddress}
              >
                <span>Patient Address:</span>
                {` ${formatFullAddress(patientInfo)}`}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default PatientInformationComponent;
